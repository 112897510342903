<template>
  <el-main>
    <!-- 视频号 -->
    <div class="function_title">交易组件</div>
    <div class="function_desc tips">
      为了理解用户交易行为、保障用户在小程序生态汇总的交易体验、打通微信生态内场景，菜单数字已接入微信自定义版交易组件，接入后，可实现与视频号 的关联以及视频号直播带货。
    </div>
    <!-- 申请步骤条 -->
    <CdSteps :active="active">
      <Steps title="小程序基本信息检查">
        <template #description>
          <div class="tips">小程序版本符合，且认证主体为企业，把“自定义版交易组件权限”授权给菜单数字的小程序才可以开通交易组件</div>
          <div class="check_list">
            <div class="check_item" v-for="(item, index) in infoList" :key="index">
              <div class="left">
                <div class="check_title">{{ item.name }}</div>
                <div class="check_desc tips">{{ item.tips }}</div>
              </div>
              <div class="right">
                <div class="check_info">
                  <span style="margin-right: 10px" v-if="item.is_success">
                    <i style="font-size: 18px; color: green" class="el-icon-success"></i>
                    {{ item.icon_success_txt }}
                  </span>
                  <span style="margin-right: 10px" v-else>
                    <i style="font-size: 18px; color: red" class="el-icon-error"></i>
                    {{ item.icon_error_txt }}
                  </span>
                  <el-button size="small" v-if="!item.is_success" @click="() => $router.push(item.path)">{{ item.btn_error_txt }}</el-button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Steps>
      <Steps title="签约并申请开通交易组件" btn_txt="立即申请" @btnClickFn="() => (openComponentsFlag = true)">
        <template #description>
          <div class="tips">提交微信审核（最长7个工作日），审核通过后可进行视频号和小程序的关联</div>
        </template>
      </Steps>
      <Steps title="立即启用，完成自定义版交易组件开通" btn_txt="立即申请" @btnClickFn="() => (enableComponentsFlag = true)">
        <template #description>
          <div class="tips">启用后，微信小程序将在公域流量场景调用该组件， 将商品、订单数据将同步至微信，满足微信监管的同时帮助获取更多的流量。</div>
        </template>
      </Steps>
      <Steps title="添加视频号推广员" btn_txt="立即添加" @btnClickFn="() => (addPromoterFlag = true)">
        <template #description>
          <div class="tips">视频号推广接入申请成功后，可前往微信小程序后台-交易组件-场景接入添加最多1000个视频号推广员。推广员可在"视频号主页>创作者中心>商品橱窗>商品管理"中管理小程序</div>
        </template>
      </Steps>
    </CdSteps>
    <!--  -->
    <!-- 申请开通自交易组件弹框 -->
    <el-dialog title="自定义版交易组件开通申请" :visible.sync="openComponentsFlag" width="700px">
      <div class="openComponents">
        <div class="showApp">
          <div class="myApp">
            <div class="logo">
              <img :src="appInfo.logo" alt="" />
            </div>
            <div class="name">{{ appInfo.name }}</div>
          </div>
          <div class="arrow">
            <img :src="imgUrlHead + 'arrow.png'" alt="" />
          </div>
          <div class="wxVideo myApp">
            <div class="logo">
              <img :src="imgUrlHead + 'wxNumVideo.png'" alt="" />
            </div>
            <div class="name">视频号</div>
          </div>
        </div>
        <div>开通后，所有的商品都会同步至微信，仅审核通过的商品才能在小程序上推广。</div>
        <div>开通成功后，小程序可和视频号关联，可以用视频号进行商品推广</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="() => (openComponentsFlag = false)">取 消</el-button>
        <el-button type="primary" @click="onceApply">立即申请</el-button>
      </span>
    </el-dialog>
    <!--  -->
    <!-- 同步商品弹框 -->
    <el-dialog title="启用自定义版交易组件" :visible.sync="enableComponentsFlag" width="700px">
      <div class="enableComponents">
        <el-steps :active="enableComponentsActive" align-center finish-status="success">
          <el-step title="同步商品"></el-step>
          <el-step title="同步订单"></el-step>
        </el-steps>
        <div class="message">
          <i class="el-icon-info"></i>
          <div class="msg" v-if="front_status == 4">商品已审核通过，请打开微信扫描以下商品二维码，在出现交易组件启动提示弹框后，用微信支付购买商品来完成微信订单接口调用的要求。</div>
          <div class="msg" v-else>系统已帮忙创建一个商品，点击立即同步可完成微信的商品接口调用要求。注：交易组件启用成功后该商品会系统删除。</div>
        </div>
        <div :class="['shopInfo', front_status == 4 ? 'shopInfo4' : '']">
          <div class="left">
            <img :src="baiscGoodsInfo.goods_picture" alt="" />
          </div>
          <div class="right">
            <div class="name">{{ baiscGoodsInfo.goods_name }}</div>
            <div>￥{{ baiscGoodsInfo.goods_price }}</div>
          </div>
          <div class="qrcpde" v-if="front_status == 4">
            <img :src="goodsQrCode" alt="" />
          </div>
        </div>
        <div class="errTxt" v-if="front_status == 5">
          驳回原因：
          <span>{{ errTxt }}</span>
        </div>
        <div class="msgTips" v-if="front_status == 4">若扫码后出现小程序更新页面，请更新后重新扫码再进行下单支付</div>
        <div class="msgTips" v-if="front_status == 4">
          扫码支付成功后，该订单
          <b>系统将自动确认收货</b>
          并进行
          <b>自动退款</b>
          操作来完成不同的状态的订单同步
        </div>
        <div class="btnBox" v-if="front_status != 4">
          <el-button @click="onceSynchronizationGoods" type="primary" size="small">
            {{ front_status == 2 ? '立即同步' : front_status == 3 ? '同步中' : front_status == 5 ? '重新同步' : '立即同步' }}
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!--  -->
    <!-- 添加推销员弹框 -->
    <el-dialog title="提示" :visible.sync="addPromoterFlag" width="500px">
      <div class="addPromoterFlag">请前往微信小程序后台 - 交易组件 - 场景接入添加视频号推广员，添加成功后，推广员可前往“视频号主页>>创作者中心>>商品橱窗>>商店管理”中关联小程序，推广商品</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="() => (addPromoterFlag = false)">我知道了</el-button>
      </span>
    </el-dialog>
    <!--  -->
  </el-main>
</template>

<script>
import CdSteps from '@/components/Steps/index';
import Steps from '@/components/Steps/stepsComponent.vue';
import { imgUrlHead } from '@/util/config';
export default {
  components: {
    CdSteps,
    Steps,
  },
  data() {
    return {
      imgUrlHead,
      active: 0,
      extensionActive: 0,
      infoList: [
        {
          is_success: true,
          name: '小程序授权',
          tips: '请确保把小程序全部权限，都授权给平台，全部权限授权才表示授权成功。',
          icon_success_txt: '已授权',
          icon_error_txt: '未授权',
          btn_error_txt: '立即授权',
          path: '/shopManagement/wechatEcology/weixin',
        },
        {
          is_success: true,
          name: '客服电话设置',
          tips: '请填写完整有效的客服信息，有助于视频号开通交易组件过审。',
          icon_success_txt: '已设置',
          icon_error_txt: '未设置',
          btn_error_txt: '立即设置',
          path: '/set/shopSetup',
        },
        {
          is_success: true,
          name: '退货地址设置',
          tips: '请填写完整有效的商品退货地址，有助于视频号开通交易组件过审。',
          icon_success_txt: '已设置',
          icon_error_txt: '未设置',
          btn_error_txt: '立即设置',
          path: '/set/address',
        },
      ],
      appInfo: { name: '', logo: '' },
      openComponentsFlag: false,
      enableComponentsFlag: false,
      baiscGoodsInfo: {},
      enableComponentsActive: 0,
      front_status: 0,
      goodsQrCode: '',
      // 商品被驳回的原因
      errTxt: '',
      addPromoterFlag: false,
    };
  },
  created() {
    this.getCheckInfo();
  },
  methods: {
    // 步骤条进度改变
    stepChange(addNum) {
      this.active = addNum;
    },
    // 获取第一步需要检查的信息
    getCheckInfo() {
      this.$axios.post(this.$api.set.videoNumber.checkInfo).then(res => {
        if (res.code == 0) {
          // 1--完善店铺小程序信息 2--接入开通场景 3-- 商品同步中 4--商品同步成功 5--商品驳回 6--订单同步成功 7--订单同步失败 8--添加推广员  9--自定义组件开通成功
          this.front_status = res.result.wxshop.front_status;
          if (res.result.wxshop.front_status < 1) {
            this.infoList[0].is_success = res.result.step.tarding;
            this.infoList[1].is_success = res.result.step.accountinfo;
            this.infoList[2].is_success = res.result.step.refundaddress;
            this.appInfo.name = res.result.wxshop.name;
            this.appInfo.logo = res.result.wxshop.logo;
          } else if (res.result.wxshop.front_status == 1) {
            this.appInfo.name = res.result.wxshop.name;
            this.appInfo.logo = res.result.wxshop.logo;
            this.stepChange(1);
          } else if (res.result.wxshop.front_status == 2 || res.result.wxshop.front_status == 3 || res.result.wxshop.front_status == 4 || res.result.wxshop.front_status == 5) {
            this.appInfo.name = res.result.wxshop.name;
            this.appInfo.logo = res.result.wxshop.logo;
            this.getBaiscGoodsInfo();
            this.stepChange(2);
            if (res.result.wxshop.front_status == 4) {
              this.enableComponentsActive = 1;
              this.getGoodsQrCode();
            }
            if (res.result.wxshop.front_status == 4) {
              this.errTxt = res.result.wxshop.reject_reason;
            }
          } else if (res.result.wxshop.front_status == 8) {
            this.stepChange(3);
          } else if (res.result.wxshop.front_status == 9) {
            this.stepChange(4);
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    onceApply() {
      this.$axios.post(this.$api.set.videoNumber.registerCheck).then(res => {
        if (res.code === 0) {
          this.getCheckInfo();
          this.$message.success('已成功开通交易组件');
          this.openComponentsFlag = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getBaiscGoodsInfo() {
      this.$axios.post(this.$api.set.videoNumber.getSystemGoods).then(res => {
        if (res.code === 0) {
          this.baiscGoodsInfo = res.result;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    onceSynchronizationGoods() {
      if (this.front_status == 2 || this.front_status == 5) {
        this.$axios.post(this.$api.set.videoNumber.addDefaultProduct).then(res => {
          if (res.code === 0) {
            this.$message.success('申请成功，请等待微信审核');
            // 更新商品审核中的状态
            this.front_status = 3;
          } else {
            this.$message.error(res.msg);
          }
        });
      } else if (this.front_status == 3) {
        this.$message.warning('微信审核中，请等待！');
      }
    },
    getGoodsQrCode() {
      this.$axios.post(this.$api.set.videoNumber.getQrcode).then(res => {
        if (res.code === 0) {
          this.goodsQrCode = res.result;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  .function_title {
    font-size: 20px;
    font-weight: 700;
    color: #323234;
    margin-bottom: 7px;
  }
  .function_desc {
    margin-bottom: 20px;
  }
  .tips {
    line-height: 1.5;
    font-size: 12px;
    color: #939496;
  }
  .check_list {
    max-width: 80%;
    margin: 30px 0 60px 0;
    .check_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border: 1px solid #d8d8d8;
      .check_title {
        font-weight: bold;
      }
    }
    .dialog_check_item > div {
      flex: 1;
    }
    .check_item:not(:first-child) {
      border-top: none;
    }
  }
  .openComponents {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > div {
      margin-bottom: 10px;
    }
    .showApp {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .arrow {
        margin: 0 40px;
      }
    }
    .myApp {
      width: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .logo {
        margin-bottom: 10px;
        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          border: 1px solid #e4e4e4;
        }
      }
      .name {
        text-align: center;
      }
    }
  }
  .enableComponents {
    display: flex;
    flex-direction: column;
    align-items: center;
    .el-steps {
      width: 100%;
    }
    .message {
      margin: 10px 0 20px 0;
      width: 100%;
      padding: 10px 20px;
      background-color: #edf4ff;
      border: 1px solid #c0d4f5;
      display: flex;
      i {
        color: #005cc8;
        margin-right: 5px;
      }
    }
    .errTxt {
      align-self: flex-start;
      margin-top: 20px;
      font-size: 12px;
      span {
        color: red;
      }
    }
    .shopInfo {
      display: flex;
      padding: 5px;
      box-shadow: 0 0 10px 5px #e4e4e4;
      max-width: 500px;
      align-self: flex-start;
      .left {
        margin-right: 5px;
        img {
          width: 100px;
          height: 100px;
          border-radius: 5px;
        }
      }
      .right {
        max-width: 250px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .shopInfo4 {
      align-self: center;
      max-width: 100% !important;
      // img {
      //   width: 150px !important;
      //   height: 150px !important;
      // }
    }
    .qrcpde {
      img {
        width: 100px;
        height: 100px;
      }
    }
    .msgTips {
      margin-top: 10px;
    }
    .btnBox {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
